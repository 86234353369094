$website-width: 90%;
$peach-color: #f3be95;
$peach-light-color: #efd2b1;
$steel-color: #5B85AA;
$steel-light-color: #8599b5;
$steel-dark-color: #314e5d;
$space-color: #2D3047;
$space-dark-color: #212434;
$aero-color: #D7FDEC;
$blue-green-color: #A2E8DD;
$background: #f9fafb;
$info-color: #b4d9fe;