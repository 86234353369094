.MessageDisplay{
    margin-bottom: 1rem;
    // border-bottom: grey 1px solid;
    .pointer{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: $peach-color;
        align-self: center  ;
        &-author{
            background-color: $space-color;
        }
    }
    .message-title{
        align-self: center;
        margin: 0;
    }
    .author{
        margin: 0 0 0 5px;
        span{
            font-weight: bold;
        }
    }   
    .message{
        margin: 0 0 7px 15px;
        max-width: 75%;
    }
}