.Loader{
    background-color: rgba(0,0,0,0.3);
    height: 100vh;
    width: 100vw;
    top: 0; right: 0; left: 0; bottom: 0;
    justify-content: center;
    align-items: center;
    position: fixed;
    display: flex;
    z-index: 5;
    .spinner{
        border: 7px solid white;
        height: 50px;
        width: 50px;
        border-radius: 50%;
        border-top: 7px solid $peach-color;
        animation: spin 1s infinite;
    }
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}
