.TicketsList{
            width: 100%;
            min-height: 600px;
            max-height: 100%;
            background:white;
            border-radius: 5px;
            box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
            .header{
                background: $steel-color;
                display: flex;
                align-items: center;
                height:80px;
                padding-left: 15px;
                color: white;
            }
            .list{
                height: 100%;
                // height: calc(100% - 80px);
                .list-item{
                    margin: none;
                    background:#F9FAFB;
                    border-bottom: 1px solid grey;
                    transition: 0.25s all;
                    padding: 0 1rem;
                    cursor: pointer;
                    display: flex;
                    color: black;
                    justify-content: space-between;
                    &:hover{
                        background: #ADADAE;
                    }
                    .title{
                        width:70%;
                    }
                    .date{
                        width: 10%;
                    }
                    .status{
                        width: 10%;
                    }
                }
                
            }
        }