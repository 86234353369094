@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
@import "variables";

body{
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  position: relative;
  min-height: 100vh;
  background: $background;
}
ul{list-style: none;}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container{
  max-width: $website-width;
  padding: 0 1.5rem;
  margin: auto;
  overflow: hidden;
  height: 100%;
  width: 100%;
}
.py-1{padding: 1.5rem 0;}
.py-2{padding: 2rem 0;}
.py-3{padding: 3rem 0;}
.p-1{padding: 1.5rem;}
.p-2{padding: 2rem;}
.p-3{padding: 3rem;}
.my-1{margin: 1.5rem 0;}
.my-2{margin: 2rem 0;}
.my-3{margin: 3rem 0;}
.m-1{margin: 1.5rem;}
.m-2{margin: 2rem;}
.m-3{margin: 3rem;}
.text-center{
  text-align: center;
}
.hidden{display: none}
.flex{
  display: flex;
}
.grid-box{
  display: grid;
}
a{
  text-decoration: none;
  color: #ffffff;
}
p{
  margin: 0.75rem 0;
}
.text-center{
  text-align: center;
}
.text-green{
  color: green;
}
.text-error{
  font-size: 1.4rem;
  color: #d45050;
  background: #565356;
}
.heading{
  font-size: 18px;
  font-weight: bold;
}
.btn{
  display: inline-block;
  padding: 0.5rem 1.5rem;
  margin: 0.3rem;
  border-radius: 5px;
  // border: none;
  &:hover{
    cursor: pointer;
  }
  &-primary{
    background: #CC7E85;
    color: white;
    &:hover{
      background: #b36d74;
    }
  }
  &-dark{
    background: #314e5d;
    color: white;
    &:hover{
      background: lighten(#314e5d, 5);
    }
  }
  &-light{
    color: #314e5d;
    background: white;
    &:hover{
      background: darken(white, 5);
    }
  }
}
.sr-only{
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}