.SideNav{
    width: 280px;
    height: 100vh;
    background: $peach-color;
    padding-top: 80px;
    z-index: 1;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    .nav{
        width: 100%;
        .nav-item{
            width: 100%;
            height: 50px;
            display: flex;
            margin-bottom: 0.3rem;
            align-items: center;
            cursor: pointer;
            .icon{
                margin-left: 1rem;
            }
            p{
                margin-left: 1.5rem;
                font-size: 1.1rem
            }
            &:hover{
                background-color: darken($color: $peach-color, $amount: 5)
            }
            &.active{
                background-color: $steel-dark-color;
            }
        }
    }

@media screen and (max-width: 1280px){
    width: 50px;
    a{
        p{
            display: none;
        }
    }
}
}
.Viewport{
    width: calc(100% - 280px);
    height: 100vh;
    padding: 70px 0 0 0;
    margin: auto;

    @media screen and (max-width: 1280px){
        width: calc(100% - 50px);
    }
}