.Header{
  background-color: $peach-color;
  height: 70px;
  padding: 0 1.5rem;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  z-index: 2;
  position: fixed;
  width: 100%;
  top: 0;
  position: fixed;
  .nav{
    display: flex;
    .logo{
      font-family: 'Dancing Script', cursive;
      font-size: 1.4rem;
      font-weight: 700;
      color: $space-color;
      margin-right: 1rem;
    }
    ul{
      display: flex;
      align-items: center;
      .nav-item{
        background-color:$steel-dark-color;
        margin-left: 1rem;
        padding: 0.5rem 0.9rem;
        font-size: 0.9rem;
        border-radius:10px;
        &:hover{
          background-color:$steel-color;
        }
      }
    }
  }
  .user{
    display: flex;
    position: relative;
    button{
      font-size: 1.2rem;
      height: 45px;
      width: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content:center;
      color: white;
      // border: 1px solid white;
      border: none;
      background-color:$steel-dark-color;
      margin-left: 0.75rem;
      cursor: pointer;
      &:hover{
      background-color:$steel-color;
      }
    }
    .dropdown{
      background-color: white;
      display: flex;
      flex-direction: column;
      position: absolute;
      color: $space-color;
      height: auto;
      top: 50px;
      right: 0;
      border-radius: 10px;
      min-width: 150px;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      box-sizing:initial;
      .dropdown-item{
        color: $space-color;
        text-align: center;
        padding: 0.25rem 1.25rem;
        border-bottom: 1px solid #999;
        // border-radius: 10px;
        &:last-child{
          border: none;
          border-radius: 0 0 10px 10px;
        }
        &:first-child{
          border-radius: 10px 10px 0 0 ;
        }
        &:hover{
          background-color: darken($color: white, $amount: 3)
        }
        &.fixed{
          cursor: default;
          &:hover{
            background-color: white
          }
        }
      }
      
    } 
  }
}