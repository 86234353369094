.MessageReply{
    width: 100%;
    margin: 1rem auto 0 auto;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
    0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    textarea{
        width: 100%;
        min-height: 200px;
        resize: none;
        padding: 5px 5px;
        padding: 10px;
        font-family: 'Roboto';
        font-size: 0.95rem;
        border: 1px solid darkgray;
    }
    .bottom-toolbar{
        width: 100%;
        height: 50px;
        padding: 10px 5px;
        justify-content: right;
        display: flex;
        background-color: darken($color: white, $amount: 5);
        align-items: center;
    }
}