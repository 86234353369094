.Notification{
    width: 500px;
    height: 300px;
    background-color: white;
    border-radius: 5px;
    position: absolute;
    top: 50px;
    right: 10px;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.20),
            0px 4px 5px 0px rgba(0, 0, 0, 0.14),
            0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    overflow: auto;
    z-index: 100;
    .no-notification{
        text-align: center;
        margin-top: 3rem;
    }
    .NotificationItem{
        min-height: 60px;
        border-bottom: 1px solid grey;
        padding: 0.5rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        &:hover{
            background-color: darken($color: white, $amount: 5);
        }
        .pointer{
            border-radius: 50%;
            width: 10px;
            height: 10px;
            margin-right: 10px;
            background: #2D3047;
        }
        .content{
            width: 90%;
            p{
                font-size:0.85rem;
                max-width: 100%;
                margin: 0 0 0.2rem 0;
            }
        }
        
    }
}

