.Form{
    width: 100%;
    height: 100%;
    padding: 0.5rem 1rem;
    .error{
        .form-error{
            text-align: center;
            color: red;
        }
    }
    .form-group{
        margin-bottom: 2rem;
    }
    label{
        display: block;
    }
    input,button{
        width: 100%;
        height: 40px;
    }
    textarea, input{
        border: 1px solid #9a9a9a;
        border-radius: 10px;
        padding: 0.2rem 0.5rem;
        outline: none;
        background: white;
        font-size: 0.9rem;
        &:focus{
            border: 3px solid $peach-color;
            z-index: 5;
        }
        &:hover{
            border-color: $peach-color;
        }
    }
    button{
        background: $peach-color;
        border: none;
        border-radius: 10px;
        color: white;
        font-size: 1rem;
        font-weight: 700;
        cursor: pointer;
        position: relative;
        &:hover{
            background: darken($color: $peach-color, $amount: 5)
        }
        .icon{
            position: absolute;
            color: lighten($color: $peach-color, $amount: 7);
            left: 15px;
        }
    }
}

.LoginForm.Form{
    input{
        border-radius: 0;
        &:first-of-type{
            border-radius: 10px 10px 0 0;
            border-bottom: none;
        }
        &:last-of-type{
            border-radius: 0 0 10px 10px;
            border-top: none;
        }
        &:focus{
            border: 3px solid $peach-color;
            z-index: 5;
        }
    }
    .no-account{
        text-align: center;
        a{
            color: $steel-color;
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.NewTicketForm.Form{
    margin-top: 1rem;
    padding: 0 2rem;
    textarea{
        width: 100%;
        min-height: 200px;
        resize: none;
        padding: 10px;
        font-family: 'Roboto';
        font-size: 0.95rem;  
    }
}